import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Line from '../../common/line/Line';
import Box from '../../common/box/Box';
import LightTooltip from '../../common/tooltip/Tooltip';
import Icon from '../../common/icon/Icon';
import Text from '../../common/text/Text';
import AvailableCapacityIcon from '../../../assets/icons/AvailableCapacityIcon';
import { formatStorageValue } from '../../../utils/format-storage-values.utils';
import { formatNumber } from '../../common/card/CardSimple';

export default function CapacityCard({ dataCard, theme, icon, statusNumber }) {
  const { t } = useTranslation();
  const [newStatusNumber, setNewStatusNumber] = useState('');
  const [statusNumberSymbol, setStatusNumberSymbol] = useState('');

  useEffect(() => {
    if (statusNumber?.toString().includes(' ')) {
      setNewStatusNumber(+statusNumber?.split(' ')[0]);
      setStatusNumberSymbol(' ' + statusNumber?.split(' ')[1]);
    } else {
      setNewStatusNumber(statusNumber);
    }
  }, [statusNumber]);

  return (
    <CapacityCardStyled
      display="grid"
      gridcolumn="auto repeat(2, 2fr) 1fr"
      gap="20px"
      bgcolor="white"
      boxshadow="0px 2px 4px rgba(0, 0, 0, 0.0999235)"
      minheightsize="35px"
      p="20px"
      borderradius="4px"
      relative="true"
    >
      <Line
        color={theme.themes?.colorPrimary}
        absolute="true"
        t="0"
        l="0"
        borderRadius="4px 4px 0 0"
        borderWidth="3px"
      />

      <AvailableCapacityIcon
        width="60px"
        height="60px"
        fill={theme.themes?.colorPrimary}
      />
      <Box
        display="flex"
        direction="column"
        alignitems="flex-start"
        justifycontent="center"
        gap="7px"
      >
        <BigCardText>
          {typeof dataCard?.totalCapacity === 'number'
            ? formatStorageValue(dataCard?.totalCapacity, 'totalCapacity')
            : '--'}

          {Boolean(newStatusNumber) && (
            <Status
              borderradius="4px"
              p="2px 5px"
              ml="5px"
              display="flex"
              alignitems="center"
              color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
              bgcolor={
                newStatusNumber >= 0 ? 'rgba(23, 209, 79, 10%)' : '#FAE5E5'
              }
            >
              {icon && (
                <Icon
                  display="flex"
                  width="8px"
                  height="8px"
                  mr="5px"
                  icon={newStatusNumber >= 0 ? 'arrow_up' : 'arrow_down_sec'}
                ></Icon>
              )}
              <Text
                fontSize="14px"
                fontWeight="500"
                color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
              >
                {newStatusNumber &&
                  formatNumber(
                    newStatusNumber?.toString().replace('-', '')
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + statusNumberSymbol}
              </Text>
            </Status>
          )}
        </BigCardText>

        <CardText
          display="flex"
          direction="row"
          alignitems="center"
          justifycontent="center"
        >
          {t('totalCapacity')}
          <LightTooltip
            maxWidth={230}
            title={t('totalCapacityMessage')}
            placement="bottom"
          >
            <Box display="flex" alignitems="center" justifycontent="center">
              <Icon icon="info_icon" width="11px" ml="5px" mb="1px" />
            </Box>
          </LightTooltip>
        </CardText>
      </Box>
      <Box
        display="flex"
        direction="column"
        alignitems="flex-start"
        justifycontent="space-around"
        gap="7px"
      >
        <CardText
          display="flex"
          direction="row"
          alignitems="center"
          justifycontent="center"
        >
          {t('usedCapacity')}
          <LightTooltip
            maxWidth={230}
            title={t('usedCapacityMessage')}
            placement="bottom"
          >
            <Box display="flex" alignitems="center" justifycontent="center">
              <Icon icon="info_icon" width="11px" ml="5px" mb="1px" />
            </Box>
          </LightTooltip>
        </CardText>

        <CardText
          display="flex"
          direction="row"
          alignitems="center"
          justifycontent="center"
        >
          {t('remainCapacity')}
        </CardText>
      </Box>
      <Box
        display="flex"
        direction="column"
        alignitems="flex-start"
        justifycontent="center"
        gap="7px"
      >
        <SmallCardText>
          {typeof dataCard?.usedCapacity === 'number'
            ? formatStorageValue(dataCard?.usedCapacity, 'usedCapacity')
            : '--'}
          {Boolean(newStatusNumber) && (
            <Status
              borderradius="4px"
              p="2px 5px"
              ml="5px"
              display="flex"
              alignitems="center"
              color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
              bgcolor={
                newStatusNumber >= 0 ? 'rgba(23, 209, 79, 10%)' : '#FAE5E5'
              }
            >
              {icon && (
                <Icon
                  display="flex"
                  width="8px"
                  height="8px"
                  mr="5px"
                  icon={newStatusNumber >= 0 ? 'arrow_up' : 'arrow_down_sec'}
                ></Icon>
              )}
              <Text
                fontSize="14px"
                fontWeight="500"
                color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
              >
                {newStatusNumber &&
                  formatNumber(
                    newStatusNumber?.toString().replace('-', '')
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + statusNumberSymbol}
              </Text>
            </Status>
          )}
        </SmallCardText>
        <SmallCardText>
          {typeof dataCard?.remainingCapacity === 'number'
            ? formatStorageValue(
                dataCard?.remainingCapacity,
                'remainingCapacity'
              )
            : '--'}
          {Boolean(newStatusNumber) && (
            <Status
              borderradius="4px"
              p="2px 5px"
              ml="5px"
              display="flex"
              alignitems="center"
              color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
              bgcolor={
                newStatusNumber >= 0 ? 'rgba(23, 209, 79, 10%)' : '#FAE5E5'
              }
            >
              {icon && (
                <Icon
                  display="flex"
                  width="8px"
                  height="8px"
                  mr="5px"
                  icon={newStatusNumber >= 0 ? 'arrow_up' : 'arrow_down_sec'}
                ></Icon>
              )}
              <Text
                fontSize="14px"
                fontWeight="500"
                color={newStatusNumber >= 0 ? '#027A48' : '#B42318'}
              >
                {newStatusNumber &&
                  formatNumber(
                    newStatusNumber?.toString().replace('-', '')
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + statusNumberSymbol}
              </Text>
            </Status>
          )}
        </SmallCardText>
      </Box>
    </CapacityCardStyled>
  );
}

const CardText = styled(Box)`
  color: #0b293a;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
`;

const BigCardText = styled.p`
  display: inline-flex;
  align-items: center;
  color: #0b293a;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const SmallCardText = styled.p`
  display: inline-flex;
  align-items: center;
  color: #0b293a;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const CapacityCardStyled = styled(Box)`
  grid-column: 1 / 3;
`;

const Status = styled(Box)``;
CapacityCard.propTypes = {
  dataCard: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  icon: PropTypes.element.isRequired,
  statusNumber: PropTypes.number.isRequired
};
